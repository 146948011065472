import 'core-js/stable';
import 'regenerator-runtime/runtime';

// IMPORT SASS
import '../sass/style.scss';
import handleScenes from './scenes'
import Preload, { Order } from 'image-preload';
import { sequencesRwf, sequencesSog } from './sequences.js'
import magnificPopup from "magnific-popup"

//import { setAnonymousAppUserId } from './appUser'
import { clickTrack } from "./clickTracker"

const url = window.location.href
const env = url.includes('local') ? 'dev' : 'production'
const host = env=='dev' ? "https://west-fraser.local/img-seq" : "https://d3gnvehrrebc5j.cloudfront.net"


//const appUserId = au ? au : setAnonymousAppUserId()

document.addEventListener("DOMContentLoaded", function(){

    // turn off auto scroll
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }

    $('[data-c-t]').on('click', function(e){
        clickTrack($(this))
        e.preventDefault()
    })

    $('.header .contact a').magnificPopup({
            type: "inline"
    })

    // setup touch events
    window.addEventListener('touchstart', function onFirstTouch() {
        // perform action here and remove listener
        $('body').removeClass('no-touch')
        $('body').addClass('touch')
        window.removeEventListener('touchstart', onFirstTouch, false);
    }, false);

    
    // FOOTER LOGOS - touch
    $('.footer .logo img').on('click', function(){
        if($('body').hasClass('touch')) {
            $('.footer .active').removeClass('active')
            $(this).closest('.logo').addClass('active')

            const modal = $('#tt-modal')
            modal.find('.tooltip').html('<div class="offset"><div class="content">' + parent.find('.tooltip').html() + '</div></div>')
            modal.addClass('active')
        }
    })

    // desktop
    $('.no-touch .footer .logo img').hover(function(){
        $('.footer .active').removeClass('active')
        parent = $(this).closest('.logo')
        parent.addClass('active')
        
    }, function(){
        $('.footer .active').removeClass('active')
        $('#tt-modal').removeClass('active')
    })

    $('.footer .close').on('click', function(){
        $('.footer .active').removeClass('active')
        $('#tt-modal').removeClass('active')
    })

    $('.welcome button').on('click', function() {
        $('body').addClass('preloading')
        setTimeout(() => {
            const buildType = $(this).attr('class')
            initialPreload(buildType)                
        }, 30);
    })

    // MENU TOGGLE
    $('.header-menu').hover(function(){

        $('body.no-touch').addClass('menu-active')            
    }, function(){
        $('body.no-touch').removeClass('menu-active')
    })
    $('.menu-toggle').on('click', function(e){
        if($('body').hasClass('touch')) {
            $('body').toggleClass('menu-active')
        }
        e.preventDefault()
    })

    $('.header .menu a').on('click', function(e){
        $('body').removeClass('menu-active')
        e.preventDefault()
    })

    $('a.scroller').on('click', function(e){
        const target = $(this).attr('data-scroll')
        let pixels = $(target).offset().top
        if(target == '.connect' || target == '.testimonials')
            pixels = pixels - $('.header').outerHeight()

        $('.scroll-overlay > .inner').animate({
            opacity: 0.08
        }, 40)

        $("html, body").animate({
            scrollTop: pixels
        }, 500, "swing", function(){
            $('.scroll-overlay > .inner').animate({
                opacity: 1,
            })
        });
        e.preventDefault()
    })   
})
window.addEventListener('load', function(event) {
    loadLiveChat();    
})

// LIVE CHAT
function loadLiveChat() {
    window.__lc = window.__lc || {};
    window.__lc.license = 12536178;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
}

// PRELOAD
function initialPreload(buildType) {
    let totalCount = 0
    let totalLoaded = 0
    const imagesToPreloadFirst = []
    const imagesToPreloadSecond = []

    const first = buildType == 'rwf' ? sequencesRwf : sequencesSog
    for (let i = 0; i < first.length; i++) {
        for (let j = 0; j < first[i].count; j++) {
            const img = new Image();
            img.src = host + first[i].src + `image${(j).toString().padStart(3, '0')}.jpg?v=2`;
            first[i].images.push(img);
            imagesToPreloadFirst.push(img.src)
            totalCount++
        }
    }

    const second = buildType == 'rwf' ? sequencesSog : sequencesRwf
    for (let i = 0; i < second.length; i++) {
        for (let j = 0; j < second[i].count; j++) {
            const img = new Image();
            img.src = host + second[i].src + `image${(j).toString().padStart(3, '0')}.jpg?v=2`;
            second[i].images.push(img);
            imagesToPreloadSecond.push(img.src)
        }
    }

    const secondPreloadComplete = () => { 
        //console.log("all images preloaded")
    }


    let enoughPreloaded = false
    const firstPreloadComplete = () => { 
        //console.log("first batch preloaded")

        if(!enoughPreloaded) {
            enoughPreloaded = true
            initializeScenes
        }
    }    

    const singleImageComplete = () => {
        totalLoaded++;
        const loadedPercent = Math.ceil(totalLoaded / totalCount * 100)
        const targetPercentage = (env=="dev") ? 100 : 100

        if(!enoughPreloaded && loadedPercent >= targetPercentage) {
            enoughPreloaded = true
            initializeScenes()

        } else {
            $('.preloader .progress').html(loadedPercent + '%')
            $('.preloader .bar-inner').css('width', loadedPercent + '%')
        }
    }

    Preload(imagesToPreloadFirst, {
        order: Order.AllAtOnce,
        onComplete: firstPreloadComplete,
        onSingleImageComplete: singleImageComplete,
    })

    const initializeScenes = () => {
        $('body').removeClass('preloading');
        $('body').addClass('scenes-active');
    
        handleScenes(sequencesRwf, sequencesSog, buildType)
    
        Preload(imagesToPreloadSecond, {
            order: Order.AllAtOnce,
            onComplete: secondPreloadComplete,
        }) 
    }
}

