
export const scrollPromptInitialScroll = () => {
    window.addEventListener('scroll', handleInitialScroll)
}

function handleInitialScroll() {
    if($(window).scrollTop() > 300) {
        $('.scroll-prompt.global').addClass('scrolled-active')
        window.removeEventListener('scroll', handleInitialScroll)
    }
}

export const scrollPromptShow = () => {
    $('.scroll-prompt.global').addClass('active')
    setTimeout(() => {
        $('.scroll-prompt.global').removeClass('active')
    }, 3000)
}

export const scrollPromptHide = () => {
    $('.scroll-prompt.global').removeClass('active')
}