export const sequencesRwf = [{
    id: 1,
    count: 130,
    images: [],
    src: "/RWF/Scene1/",
},{
    id: 2,
    count: 168,
    images: [],
    src: "/RWF/Scene2/",
},{
    id: 3,
    count: 94,
    images: [],
    src: "/RWF/Scene3/",
},{
    id: 4,
    count: 112,
    images: [],
    src: "/RWF/Scene4/",
},{
    id: 5,
    count: 221,
    images: [],
    src: "/RWF/Scene5/",
},{
    id: 6,
    count: 0,
    images: [],
    src: "/RWF/Scene6/",
},{
    id: 7,
    count: 196,
    images: [],
    src: "/RWF/Scene7/",
},{
    id:8,
    count: 117,
    images: [],
    src: "/RWF/ZOutro/" 
},{
    id: 2.5,
    count: 56,
    images: [],
    src: "/RWF/Scene2HT/",
}];

export const sequencesSog = [{
    id: 1,
    count: 118,
    images: [],
    src: "/SOG/Scene1/",
},{
    id: 2,
    count: 164,
    images: [],
    src: "/SOG/Scene2/",
},{
    id: 3,
    count: 84,
    images: [],
    src: "/SOG/Scene3/",
},{
    id: 4,
    count: 116,
    images: [],
    src: "/SOG/Scene4/",
},{
    id: 5,
    count: 222,
    images: [],
    src: "/SOG/Scene5/",
},{
    id: 6,
    count: 0,
    images: [],
    src: "/SOG/Scene6/",
},{
    id: 7,
    count: 178,
    images: [],
    src: "/SOG/Scene7/",
},{
    id: 8,
    count: 117,
    images: [],
    src: "/SOG/ZOutro/",
},{
    id: 2.5,
    count: 168,
    images: [],
    src: "/SOG/Scene2HT/",
}];