const url = window.location.href
let id = 0

export function setAnonymousAppUserId() {
    const u = {
        f: "Anonymous",
        l: "User",
        r: "0",
        c: "",
    }

    var settings = {
        "url": '/api',
        "method": "POST",
        "timeout": 0,
        "headers": {
        "Authorization": 'Bearer bGI47lpjudnNOkvn4zZEg1U8fzgGTYKC',
        "Content-Type": "application/json"
        },
        "data": JSON.stringify({
        query: "mutation saveEntry($title:String, $slug:String, $authorId:ID, $id:ID, $registrationId:String, $firstName:String, $lastName:String, $company:String) {\n save_appUsers_appUsers_Entry(title: $title, slug: $slug, authorId: $authorId, id: $id, registrationId: $registrationId, firstName: $firstName, lastName: $lastName, company: $company) {\n    title\n    slug\n    id\n    registrationId\n    firstName\n    lastName\n    company\n  }\n}\n",
        variables: {"title":u.f + " " + u.l + " " + Date.now(),"slug":"appuser","authorId":1,"registrationId":u.r,"firstName":u.f,"lastName":u.l,"company":u.c}
        })
    };

    $.ajax(settings).done(function (response) {
        id = response.data.save_appUsers_appUsers_Entry.id
        return id
    });
}

export function getAppUserId() {
    return id
}