export default function handleSceneSize() {

    $('.scroll-overlay > .inner').css('width', '100%')

    const viewableArea = $(window).outerHeight() - ($('.header').outerHeight() + $('.footer').outerHeight())
    const overlayHeight = $('.scroll-overlay > .inner').outerHeight() + $('.scene-active .s-header').first().outerHeight() + 40
    const dif = viewableArea - overlayHeight

    if(dif < 0) {
        $('.scroll-overlay > .inner').width($('.scroll-overlay > .inner').width() + (dif*2.3))
    } 
}