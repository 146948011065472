import gsap from 'gsap'
import handleSceneSize from './sceneSizer'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import slick from 'slick-slider'
import {scrollPromptShow, scrollPromptHide, scrollPromptInitialScroll} from './scrollPrompt'

export default function handleScenes(sequencesRwf, sequencesSog, buildType) {
 
    $('body').addClass(buildType)
    $('body').removeClass('init')
    window.scrollTo(0, 0)
    
    scrollPromptInitialScroll()

    const isMobile = () => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true
       }
       else{
            return false
       }
    }
    let doCheckForLanscape = false
    let landscapeCheckStarted = false
    if(isMobile) doCheckForLanscape = true
    const checkForLandscape = () => {
        if(window.innerHeight < window.innerWidth && window.innerHeight < 660){
            landscapeCheckStarted = true
            $.magnificPopup.open({
                items: {
                    src: '<div class="mfp-inner"><button class="mfp-close"></button><p>For the best experience, please hold your device in <strong>portrait</strong> orientation. Thank you!</p></div>',
                    type:'inline'
                },
                modal: true
            });
            doCheckForLanscape = false
        }
    }

    handleSceneSize()
    if(doCheckForLanscape) checkForLandscape()
    $(window).on('resize', function(){
        handleSceneSize()
        if(doCheckForLanscape) {
            checkForLandscape()
        } else if (landscapeCheckStarted) {
            $.magnificPopup.close()
            landscapeCheckStarted = false
        }
    });

    // TESTIMONIALS
    $('.testimonials .items').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        adaptiveHeight: true,

        responsive: [{
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    })

    // BUTTON GROUP
    $('.button-group button').on('click', function(e){
        if($(this).hasClass('selected')) return

        const parent = $(this).closest('.button-group')
        const second = $(this).hasClass('second')
        parent.find('.selected').removeClass('selected')
        $(this).addClass('selected')

        const interactive = $(this).closest('.interactive')
        if(second) {
            interactive.addClass('toggled')
        } else {
            interactive.removeClass('toggled')
        }
        e.preventDefault();
    });


    // CALLOUT - TOOLTIP
    $('.touch .tt .label').not('a').on('click', function(){
        showModalTooltip($(this), true)
    })

    $('.tt .close').on('click', function(){
        hideTooltip()
    })

    $('.no-touch .tt .label').hover(function(){
        showTooltip($(this), false)
    }, function(){
        hideTooltip()
    })

    function showTooltip(el, showCloseButton) {
        $('.tt.active').removeClass('active')
        const parent = el.closest('.tt')
        const interactive = el.closest('.interactive')
        const tooltip = parent.find('.tooltip')

        parent.addClass('active').css('z-index', 500)

        const ttWidth = tooltip.hasClass('has-image') ? interactive.width() * .54 : interactive.width() * .3
        tooltip.css('width', ttWidth)        
    }

    function showModalTooltip(el, showCloseButton) {
        // const ttWidth = tooltip.hasClass('has-image') ? interactive.width() * .54 : interactive.width() * .3
        // tooltip.css('width', ttWidth)
        const parent = el.closest('.tt')
        const tooltip = parent.find('.tooltip')

        const modal = $('#tt-modal')
        modal.find('.tooltip').html(tooltip.html())
        modal.addClass('active')
        
        if(showCloseButton) {
            $('.tt.active .close').show()
        }        
        
        $('#tt-modal .close').on('click', function(){
            $('#tt-modal').removeClass('active')
        })
    }


    function hideTooltip() {
        $('.tt.active .close').hide()
        $('.tt.active').removeClass('active').css('z-index', 300)
    }


    // CALLOUT - VIDEO
    $('.vid .label').on('click', function(){
        const url = $(this).attr("data-video")
        const poster = $(this).attr("data-video-poster") ? $(this).attr("data-video-poster") : ''
        const video = $(this).closest('.scene-content').find('video')
        video.attr('poster', poster)
        video.attr("src", url)
        video.find('source').attr('src', url)
        video.get(0).load()
        video.get(0).play()
        video.closest('.video').addClass('active')
    });
    $('.video .close').on('click', function(){
        const p = $(this).closest('.video')
        p.removeClass('active')
        p.find('video').get(0).pause()

        scrollPromptShow()

    })

    // CALLOUT - MODAL
    $('.callout.formModal > a').magnificPopup({
        type: "inline"
   })

       // CALLOUT - MODAL
    $('.image-modal > a').magnificPopup({
        type: "inline"
    })

    let sequences = buildType == 'rwf' ? sequencesRwf : sequencesSog

    // initial frame was buggy, so adding first frame as bg
    function setInitialBackgroundImage() {
        $('canvas').css('background-image', "url(" + sequences[0].images[0].src + ")")
    }
    setInitialBackgroundImage()


    // SWITCHEROO
    $('.switcheroo button').on('click', function(){
        const buildType = $(this).attr('data-build-type')
        if(buildType == 'rwf') {
            $('body').removeClass('sog').addClass('rwf')
        } else {
            $('body').removeClass('rwf').addClass('sog')
        }
        sequences = buildType == 'rwf' ? sequencesRwf : sequencesSog

        
        buildSequenceAnimations()

        // take down opacity before scroll
        $('.scroll-overlay > .inner').animate({
            opacity: 0.03
        }, 40)

        // scroll
        $('html,body').animate({
            scrollTop: 0
        }, 700, "swing", function(){
            render(1, 1)
            $('.scroll-overlay > .inner').animate({
                opacity: 1,
            }, 300)
        })

        $('.interactive.toggled').removeClass('toggled')
        $('.interactive .button-group .selected').removeClass('selected')
        $('.interactive .button-group button:first-child').addClass('selected')

    })


    // FOOTER
    function enterMain() {
        $('body').addClass('footer-active')
    }
    function leaveMain() {
        $('body').removeClass('footer-active')
    }
    ScrollTrigger.create({
        trigger: $(".scenes-intro"),
        start: 'top top+=20%',
        end: "bottom bottom",
        onEnter: () => enterMain(),
        onLeave: () => leaveMain(),
        onEnterBack: () => enterMain(),
        onLeaveBack: () => leaveMain(),
    });


    // SCROLL OVERLAY - IN / OUT
    gsap.to(".scroll-overlay", {
        scrollTrigger: {
            trigger: ".scenes",
            start: "top top",
            end: "top top-=30%",
            scrub: true,
        },
        opacity: 1,
    })
    gsap.fromTo(".scroll-overlay", { y: 0 }, {
        scrollTrigger: {
            trigger: ".scenes .connect",
            start: "top bottom",
            end: "top top",
            scrub: true,
        },
        y: "-100%",
        ease: "none",
    });



    // SETUP CANVAS
    const canvas = document.getElementById('canvas')
    const ctx = canvas.getContext("2d")
    let frames = { frame: 1 }
    const render = (sceneNumber) => {
        const i = sceneNumber - 1
        const targetFrame = (frames.frame <= 1) ? 0 : frames.frame - 1

        if(sequences[i].images[targetFrame]) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(sequences[i].images[targetFrame], 0, 0, canvas.width, canvas.height)
        } else {
            //console.log(frames)
        }
    }

    const enterInteractive = (sceneNumber) => {
        enterAllInteractive(sceneNumber)      
    }
    const enterBackInteractive = (sceneNumber) => {
        enterAllInteractive(sceneNumber)
    }

    const enterAllInteractive = (sceneNumber) => {

        // show scroll prompt if video has already played
        const played = $(".s" + sceneNumber).find('.video').hasClass('played')
        if(played || sceneNumber == 7) {
            scrollPromptShow()
        }

        $('body').addClass('s' + sceneNumber + '-active')
        $('.s' + sceneNumber).addClass('scene-active')
        $('.s-header' + sceneNumber).addClass('active')

        // pad top
        const header = $('.s' + sceneNumber + ' .s-header').outerHeight()
        const pad = header / 2 - 12
        $('.scroll-overlay > .inner').css('top', pad)

        handleSceneSize()

        const initialVideoUrl = $('.s' + sceneNumber).attr('data-initial-video')
        if(initialVideoUrl.length) launchInitialVideo($(".s" + sceneNumber))

        if($('.s' + sceneNumber).find('.callout').length) {
            gsap.to($(".s" + sceneNumber + " .callout"), {
                opacity: 1,
                scale: 1,
                duration: 0.5,
                ease: "power1.inOut",
            });
        }
    }
    const launchInitialVideo = (w) => {
        const v = w.find('.video')
        if(!v.hasClass('played')) {
            v.addClass('active')
            v.find('video').get(0).play()
            v.addClass('played')
        } 
    }
    const leaveInteractive = (sceneNumber) => {
        $('body').removeClass('s' + sceneNumber + '-active')
        $('.s' + sceneNumber).removeClass('scene-active')
        $('.s-header' + sceneNumber).removeClass('active')
        $('.scroll-overlay > .inner').css('top', 0)

        scrollPromptHide()
        
        // pause and close video
        const w = $('.s' + sceneNumber)
        w.find('video').get(0).pause()
        w.find('.video').removeClass('active')

        // close callouts
        if($('.s' + sceneNumber).find('.callout').length) {
            gsap.to($('.s' + sceneNumber).find('.callout'), {
                opacity: 0,
                scale: 0.5,
                duration: 0.3,
                ease: "power1.inOut",
            });
        }
    }
    const enterOut = (sceneNumber) => {
        // pause and close video
        const w = $('.s' + sceneNumber)
        w.find('video').get(0).pause()
        w.find('.video').removeClass('active')
    }

    // SEQUENCE ANIMATIONS
    const buildSequenceAnimations = () => {
        $('.scene-content').each(function(){
            var i = $(this).attr('data-scene')    
            if(i != 6) {
                if(ScrollTrigger.getById("sequence" + i)) ScrollTrigger.getById("sequence" + i).kill()

                const start = i == 1 ? "top top" : "top bottom"            
                gsap.fromTo(frames, { frame: 1 }, {
                    scrollTrigger: {
                        id: "sequence" + i,
                        trigger: $(".scene" + i + " .sequence-trigger"),
                        start: start,
                        end: "bottom bottom",
                        scrub: 0.7,
                    },
                    frame: sequences[i - 1].count,
                    snap: "frame",
                    onUpdate: () => render(i),
                })
            }
            if(i == 2) {
                if(ScrollTrigger.getById("sequence2ht")) ScrollTrigger.getById("sequence2ht").kill()

                gsap.to(frames, {
                    scrollTrigger: {
                        id: "sequence2ht",
                        trigger: $(".scene2 .interactive-trigger"),
                        start: 'top bottom',
                        endTrigger: $(".scene2 .out-trigger"),
                        end: "top bottom",
                        scrub: 0.7,
                    },
                    frame: sequences[8].count,
                    snap: "frame",
                    onUpdate: () => render(9),
                })
            }
        })
    }
    buildSequenceAnimations()

    
    // LOOP SCENES
    $('.scene-content').each(function(){
        var i = $(this).attr('data-scene')

        // INTERACTIVE
        ScrollTrigger.create({
            trigger: $(".scene" + i + " .interactive-trigger"),
            start: 'top bottom',
            end: "bottom bottom",
            onEnter: () => enterInteractive(i),
            onLeave: () => leaveInteractive(i),
            onEnterBack: () => enterBackInteractive(i),
            onLeaveBack: () => leaveInteractive(i),
        });

        // OUT
        gsap.timeline( { 
            scrollTrigger: {
                trigger: ".scene" + i + " .out-trigger",
                start: "top bottom",
                end: "bottom bottom",
                scrub: true,
                toggleActions: "play reverse play reverse",
                onEnter: () => enterOut(i),
            }
        }).fromTo(".s" + i + " .out .content", {y: "100%"}, { x:0, y: 0, duration: 0.35 })
        .to(".s" + i + " .out .content", { x: "100%", y: 0, duration: 0.35 }, 0.65)
    })

    render(1, 1)
}