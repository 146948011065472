import { getAppUserId } from './appUser'

const url = window.location.href

export function clickTrack(el) {
    //const appUserId = getAppUserId()

    const t = el.attr('data-c-t')
    const v = el.attr('data-c-v') ? el.attr('data-c-v') : el.text()

    //if(!appUserId) return
    const d = {
       title: t + " " + v,
       appUser: 0,
       clickTarget: t,
       clickValue: v,
    }    

    var settings = {
        "url": '/api',
        "method": "POST",
        "timeout": 0,
        "headers": {
        "Authorization": 'Bearer bGI47lpjudnNOkvn4zZEg1U8fzgGTYKC',
        "Content-Type": "application/json"
        },
        "data": JSON.stringify({
        query: "mutation saveEntry($title:String, $slug:String, $authorId:ID, $id:ID, $appUser:Number, $clickTarget:String, $clickValue:String) {\n save_clickEvents_clickEvents_Entry(title: $title, slug: $slug, authorId: $authorId, id: $id, appUser: $appUser, clickTarget: $clickTarget, clickValue: $clickValue) {\n    title\n    slug\n    id\n    appUser\n    clickTarget\n    clickValue\n   }\n}\n",
        variables: {"title":d.title,"slug":"clickevent","authorId":1,"appUser":d.appUser,"clickTarget":d.clickTarget,"clickValue":d.clickValue}
        })
    };

    $.ajax(settings).done(function (response) {
        const clickId = response.data.save_clickEvents_clickEvents_Entry.id
    });
}